import React from "react";
import { Link } from "gatsby";
import style from "./postCard.module.less";
import { useTranslation } from "react-i18next";

const PostCard = props => {
  const post = props.data.node;
  const { i18n } = useTranslation("common");
  const categories = post.categories.map(item => item.title.translate);

  return (
    <div className={style.postCard}>
      <Link to={`/blog/${post.slug.current}/${i18n.language}`}>
        <div
          className={style.postCardImg}
          style={{
            backgroundImage: `url(${
              post.mainImage ? post.mainImage.asset.fluid.src : ""
            })`
          }}
        />
        <div className={style.mrTp20}>
          <p>
            <span className={style.dateHolder}>
              {post ? post.date_create : ""}
            </span>
          </p>
          <h3>{post ? post.title.translate : ""}</h3>
          <p>{post ? post.excerpt : ""}</p>
          <p style={{ color: "#ce6d96", wordSpacing: "10px" }}>
            {`#${categories.join(" #")}`}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default PostCard;
