import React from "react";
import { graphql, Link } from "gatsby";
import { Layout, Row, Col, Button } from "antd";
import Header from "../components/PageLayout/Header";

import SidebarWrapper from "../components/PageLayout/Sidebar";
import PostCard from "../components/PostCard";
import SEO from "../components/seo";
import { useTranslation } from "react-i18next";

const Blog = ({ data }) => {
  const slug = "blog";

  const keywords = data.sanityPage.ceo_tags.map(item => item.translate);

  const { i18n } = useTranslation("common");

  const MenuItems = data.allSanityMenu.nodes;

  // const { currentPage, numPages } = data.pageContext;

  const { currentPage, numPages } = i18n.options.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = `/blog${
    currentPage - 1 === 1 ? "" : `/${(currentPage - 1).toString()}`
  }/${i18n.language}`;
  const nextPage = `/blog/${(currentPage + 1).toString()}/${i18n.language}`;

  return (
    <Layout className="outerPadding">
      <Layout className="container">
        <Header MenuItems={MenuItems} />

        <SEO
          lang={i18n.language}
          title={data.sanityPage.ceo_title.translate}
          description={data.sanityPage.ceo_description.translate}
          path={slug}
          keywords={keywords}
        />

        <SidebarWrapper>
          <div className="marginTopTitle">
            <h1 className="titleSeparate">{data.sanityPage.title.translate}</h1>
          </div>
          <Row gutter={[20, 20]}>
            {data.allSanityPost.edges.map((val, key) => (
              <Col key={key} xs={24} sm={24} md={12} lg={8}>
                <PostCard data={val} />
              </Col>
            ))}
          </Row>
          <Row type="flex" justify="center" align="middle">
            <Col flex="auto" justify="center" align="middle">
              {/* Проверка на первую страницу */}
              {!isFirst && (
                <Link to={prevPage} className={"ant-btn"} rel="prev">
                  ←
                </Link>
              )}

              {Array.from({ length: numPages }, (_, i) => (
                <Link
                  className={
                    i + 1 === currentPage
                      ? "ant-btn ant-btn-primary"
                      : "ant-btn"
                  }
                  key={`pagination-number${i + 1}`}
                  to={`/blog${i === 0 ? "" : `/${i + 1}`}/${i18n.language}`}
                >
                  {i + 1}
                </Link>
              ))}

              {/* Проверка на последнюю страницу */}
              {!isLast && (
                <Link to={nextPage} className={"ant-btn"} rel="next">
                  →
                </Link>
              )}
            </Col>

            {/* <Pagination defaultCurrent={currentPage} total={numPages} /> */}
          </Row>
        </SidebarWrapper>
      </Layout>
    </Layout>
  );
};

export const query = graphql`
  query Blog($language: String, $skip: Int!, $limit: Int!) {
    allSanityMenu(filter: { disabled: { in: true } }, sort: { fields: indx }) {
      nodes {
        title {
          translate(language: $language)
        }
        path
        externUrl
      }
    }
    sanityPage(slug: { current: { eq: "/blog" } }) {
      title {
        translate(language: $language)
      }
      ceo_description {
        translate(language: $language)
      }
      ceo_tags {
        translate(language: $language)
      }
      ceo_title {
        translate(language: $language)
      }
    }
    allSanityPost(
      sort: { order: DESC, fields: date_create }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title {
            translate(language: $language)
          }
          date_create(formatString: "Do MMMM, YYYY", locale: $language)
          mainImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
          categories {
            title {
              translate(language: $language)
            }
          }
        }
      }
    }
  }
`;

export default Blog;
